import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./directives/format-amount";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Cookies from "js-cookie";
import VueApexCharts from "vue-apexcharts";
import VueClipboard from "vue-clipboard2";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Toast from "vue-toastification";
import VueFileAgent from "vue-file-agent";
import "./firebase-fcm";
// eslint-disable-next-line no-unused-vars
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";

// Import the CSS or use your own
import "vue-toastification/dist/index.css";

const options = {
  // You can set your default options here
};
Vue.use(Toast, options);

Vue.use(VueFileAgent);

// import firebaseMessaging from "./firebase";

// Vue.prototype.$messaging = firebaseMessaging;

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueReCaptcha, {
  siteKey: "6LeK0oklAAAAAA8oljzNi1Aef2H4pIIRDrCrTjM9",
  loaderOptions: { autoHideBadge: true },
});

Vue.config.productionTip = false;

export const bus = new Vue();

export const availableRoles = [
  "admin",
  "operations",
  "manager",
  "cashier",
  "developer",
  "customer support",
  "human resources",
  "accountant",
];

//verify access on routing to each page
router.beforeEach(async (to, from, next) => {
  try {
    //redirect to login from 2fa page if user not logged in
    if (
      to.meta.auth === "login" &&
      (!store.getters.getUserId || !store.getters.getUser)
    ) {
      next({ name: "home" });
      return;
    }

    //redirect to login page for unauthorized access
    if (
      to.meta.auth === "user" &&
      (store.getters.getUser === "" || !Cookies.get("user-logged"))
    ) {
      next({ name: "home" });
      return;
    }

    //role verification before going to a route
    if (to.meta.auth === "user" && Cookies.get("user-logged")) {
      //if page does not have a role
      if (to.meta.roles === undefined) {
        return next();
      }

      //fetch user details
      if (store.getters.getUserDetails === "") {
        await store.dispatch("fetchUserCredentials");
      }

      //redirect users to their rightful dashboard
      if (to.name === "dashboard") {
        if (store.getters.getUser.role === "Cashier")
          return next({ name: "cashierTransactions" });
        if (store.getters.getUser.role === "Manager")
          return next({ name: "businessDashboard" });
        if (store.getters.getUser.role === "Human Resources")
          return next({ name: "payroll" });
        if (store.getters.getUser.role === "CashierMFI")
          return next({ name: "cashier-mfi" });
        if (store.getters.getUser.role === "Operations_Manager")
          return next({ name: "manager-mfi" });
        if (store.getters.getUser.role === "customer")
          return next({ name: "subaccount-dashboard" });
      }

      let userRole = (await store.getters.getUser?.role?.toLowerCase()) || "";

      let canAccess = to.meta.roles.indexOf(userRole) !== -1;
      if (canAccess) return next();
      return next({ name: "unauthorized" });
    } else next();
  } catch (e) {
    // return false
    console.log(e.message);
  }
});

//set the title of each webpage on routing and redirect to the top
router.afterEach((toRoute) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title
      ? `${toRoute.meta.title} | Boldd`
      : "Boldd";
  window.scroll(0, 0);
});

router.beforeEach((to, from, next) => {
  // prevent further redirection if already on target page
  if (to.name === from.name) {
    next();
  } else if (from.query.business && !to.query.business) {
    next({ path: to.path, query: { business: from.query.business } });
    return;
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.name) {
    // eslint-disable-next-line no-undef
    NProgress.start("1.0");
  }
  next();
});

// ?? For splash screen
router.afterEach(() => {
  // Remove initial loading
  // eslint-disable-next-line no-undef
  NProgress.done();
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

// Prevent the contextmenu event(right-click)
/* Vue.directive("disable-right-click", {
  bind: function (el) {
    el.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  },
}); */

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
